<template>
  <div class="accommodations-ledger-accounts-table">
    <!-- SEARCH -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="9" md="auto" class="mb-1 mb-md-0">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- ACCOMMODATIONS LEDGER ACCOUNTS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="accommodationsVmodels"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="row">
            <b-accommodation :uuid="row.item.uuid" :name="row.item.name" />
            <br />
            <small>
              <b-badge
                v-if="row.item.billingType === 'SUBURANCE'"
                variant="secondary"
                class="mt-25"
              >
                Subarrendament
              </b-badge>
            </small>
          </template>

          <!-- Column: zoneLedgerAccount -->
          <template #cell(zoneLedgerAccount)="row">
            <fr-form-input
              v-model="row.item.details.zoneLedgerAccount"
              placeholder="p. e. 001"
              class="zone-input"
              @update="
                autocompleteLedgerAccounts($event, row.item.details.uuid)
              "
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: depositLedgerAccount -->
          <template #cell(depositLedgerAccount)="row">
            <fr-form-ledger-account-input
              v-model="row.item.details.depositLedgerAccount"
              placeholder="p. e. 4109.1"
              class="ledger-account-input"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: securityDepositLedgerAccount -->
          <template #cell(securityDepositLedgerAccount)="row">
            <fr-form-ledger-account-input
              v-model="row.item.details.securityDepositLedgerAccount"
              placeholder="p. e. 560.1"
              class="ledger-account-input"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: touristTaxLedgerAccount -->
          <template #cell(touristTaxLedgerAccount)="row">
            <fr-form-ledger-account-input
              v-model="row.item.details.touristTaxLedgerAccount"
              placeholder="p. e. 561.1"
              class="ledger-account-input"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: servicesLedgerAccount -->
          <template #cell(servicesLedgerAccount)="row">
            <fr-form-ledger-account-input
              v-model="row.item.details.servicesLedgerAccount"
              placeholder="p. e. 5612.1"
              class="ledger-account-input"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: clientLedgerAccount -->
          <template #cell(clientLedgerAccount)="row">
            <fr-form-ledger-account-input
              v-model="row.item.details.clientLedgerAccount"
              placeholder="p. e. 430.1"
              class="ledger-account-input"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: iban -->
          <template #cell(iban)="row">
            <fr-form-iban-input
              v-model="row.item.details.iban"
              placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
              class="iban-input"
              :state="ibanState(row.item.details)"
              :state-text="ibanStateText(row.item.details)"
              :disabled="!row.item.details.uuid"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <b-button
              :disabled="!row.item.details.uuid"
              size="sm"
              class="save-button"
              :variant="getButtonVariant(row.item.button)"
              @click="saveAccommodationDetails(row.item.details.uuid)"
            >
              <b-spinner v-if="row.item.button === 'LOADING'" small />
              <feather-icon
                icon="CheckIcon"
                v-else-if="row.item.button === 'SUCCESS'"
              />
              <feather-icon
                icon="AlertTriangleIcon"
                v-else-if="row.item.button === 'ERROR'"
              />
              <span v-else>
                {{ getButtonText(row.item.button) }}
              </span>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="accommodations.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BSpinner,
  BButton,
  BBadge,
} from "bootstrap-vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import {
  FrFormInput,
  FrFormIbanInput,
  FrFormLedgerAccountInput,
} from "@/@foravila-core/components/fr-form";
import {
  formatIbanNumber,
  formatLedgerAccount,
  isIbanNumberValid,
} from "@/utils/methods";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BSpinner,
    BAccommodation,
    FrFormInput,
    FrFormIbanInput,
    FrFormLedgerAccountInput,
    BButton,
    BBadge,
  },
  props: {
    accommodations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "name",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "accommodation", label: this.$t("Allotjament"), sortable: true },
        { key: "zoneLedgerAccount", label: this.$t("Zona") },
        { key: "depositLedgerAccount", label: this.$t("Depòsits") },
        {
          key: "securityDepositLedgerAccount",
          label: this.$t("Fiances"),
        },
        {
          key: "touristTaxLedgerAccount",
          label: this.$t("Imposts turístics"),
        },
        { key: "servicesLedgerAccount", label: this.$t("Serveis") },
        { key: "clientLedgerAccount", label: this.$t("Clients") },
        // { key: "iban" },
        { key: "actions", label: this.$t("Accions"), sortable: false },
      ],
      accommodationsVmodels: null,
    };
  },
  watch: {
    accommodations(accommodations) {
      this.initVModels();
      this.totalRows = accommodations.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.initVModels();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.accommodations.length;
  },
  methods: {
    initVModels() {
      if (!this.accommodations?.length) {
        return;
      }

      this.accommodationsVmodels = this.accommodations.map((accommodation) => ({
        uuid: accommodation.uuid || null,
        name: accommodation.name || null,
        billingType: accommodation.billingType || null,
        details: {
          uuid: accommodation.details?.uuid || null,
          zoneLedgerAccount: accommodation.details?.zoneLedgerAccount || null,
          depositLedgerAccount:
            accommodation.details?.depositLedgerAccount || null,
          securityDepositLedgerAccount:
            accommodation.details?.securityDepositLedgerAccount || null,
          touristTaxLedgerAccount:
            accommodation.details?.touristTaxLedgerAccount || null,
          servicesLedgerAccount:
            accommodation.details?.servicesLedgerAccount || null,
          clientLedgerAccount:
            accommodation.details?.clientLedgerAccount || null,
          iban: formatIbanNumber(accommodation.details?.iban || null),
        },
        button: "IDLE",
      }));
    },
    autocompleteLedgerAccounts(zone, detailsUuid) {
      if (!detailsUuid) {
        return;
      }

      const foundVModelIndex = this.accommodationsVmodels.findIndex(
        (accommodation) => accommodation.details?.uuid === detailsUuid
      );

      if (foundVModelIndex === -1) {
        return;
      }

      const billingType =
        this.accommodationsVmodels[foundVModelIndex].billingType || null;

      // Deposits ledger account
      this.accommodationsVmodels[
        foundVModelIndex
      ].details.depositLedgerAccount = zone
        ? formatLedgerAccount(`4109.${zone}`)
        : null;

      // Security deposits ledger account
      this.accommodationsVmodels[
        foundVModelIndex
      ].details.securityDepositLedgerAccount = zone
        ? formatLedgerAccount(`560.${zone}`)
        : null;

      // Tourist taxes ledger account
      if (zone) {
        this.accommodationsVmodels[
          foundVModelIndex
        ].details.touristTaxLedgerAccount =
          billingType === "SUBURANCE"
            ? formatLedgerAccount(`5611.${zone}`)
            : formatLedgerAccount(`561.${zone}`);
      } else {
        this.accommodationsVmodels[
          foundVModelIndex
        ].details.touristTaxLedgerAccount = null;
      }

      // Services ledger account
      this.accommodationsVmodels[
        foundVModelIndex
      ].details.servicesLedgerAccount = zone
        ? formatLedgerAccount(`5612.${zone}`)
        : null;

      // Clients ledger account
      if (billingType === "SUBURANCE") {
        this.accommodationsVmodels[
          foundVModelIndex
        ].details.clientLedgerAccount = null;
      } else {
        this.accommodationsVmodels[
          foundVModelIndex
        ].details.clientLedgerAccount = zone
          ? formatLedgerAccount(`430.${zone}`)
          : null;
      }
    },
    sanitizeIban(ibanNumber) {
      return ibanNumber?.replace(/\s/g, "") || null;
    },
    saveAccommodationDetails(detailsUuid) {
      const foundVModelIndex = this.accommodationsVmodels.findIndex(
        (accommodation) => accommodation.details?.uuid === detailsUuid
      );

      if (foundVModelIndex === -1) {
        return;
      }

      const foundVModel = this.accommodationsVmodels[foundVModelIndex];

      if (!foundVModel?.details?.uuid) {
        return;
      }

      const { details } = foundVModel;

      this.accommodationsVmodels[foundVModelIndex].button = "LOADING";

      this.$store
        .dispatch(`accommodation/updateAccommodationDetails`, {
          uuid: details.uuid,
          zoneLedgerAccount: details?.zoneLedgerAccount || null,
          depositLedgerAccount: details?.depositLedgerAccount || null,
          securityDepositLedgerAccount:
            details?.securityDepositLedgerAccount || null,
          touristTaxLedgerAccount: details?.touristTaxLedgerAccount || null,
          servicesLedgerAccount: details?.servicesLedgerAccount || null,
          clientLedgerAccount: details?.clientLedgerAccount || null,
          // iban: this.sanitizeIban(details?.iban || null),
        })
        .then(() => {
          this.accommodationsVmodels[foundVModelIndex].button = "SUCCESS";
          setTimeout(() => {
            this.accommodationsVmodels[foundVModelIndex].button = "IDLE";
          }, 2000);
        })
        .catch(() => {
          this.accommodationsVmodels[foundVModelIndex].button = "ERROR";
          setTimeout(() => {
            this.accommodationsVmodels[foundVModelIndex].button = "IDLE";
          }, 2000);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getButtonVariant(status) {
      const defaultVariant = "primary";

      const variantsMap = {
        IDLE: defaultVariant,
        LOADING: defaultVariant,
        SUCCESS: "success",
        ERROR: "danger",
      };

      return variantsMap[status] || defaultVariant;
    },
    getButtonText(status) {
      const defaultText = "Guarda";

      const variantsMap = {
        IDLE: defaultText,
        LOADING: "Guardant",
        SUCCESS: "Guardat!",
        ERROR: "Error!",
      };

      return variantsMap[status] || defaultText;
    },
    ibanState(details) {
      const { iban } = details || {};

      if (!iban) {
        return null;
      }

      if (!isIbanNumberValid(iban)) {
        return false;
      }

      if (this.isIbanDuplicated(details)) {
        return false;
      }

      return null;
    },
    ibanStateText(details) {
      const { uuid, iban } = details || {};

      if (!iban) {
        return null;
      }

      if (!isIbanNumberValid(iban)) {
        return "El nº IBAN introduït no és vàlid";
      }

      const foundVModelIndex = this.accommodationsVmodels.findIndex(
        (accommodation) =>
          accommodation.details?.iban === iban &&
          accommodation.details.uuid !== uuid
      );

      if (foundVModelIndex === -1) {
        return null;
      }

      return `IBAN duplicat a ${this.accommodationsVmodels[foundVModelIndex].name}`;
    },
    isIbanDuplicated(details) {
      const { uuid, iban } = details || {};

      if (!iban) {
        return false;
      }

      return this.accommodationsVmodels.some(
        (accommodation) =>
          accommodation.details.iban === iban &&
          accommodation.details.uuid !== uuid
      );
    },
  },
};
</script>

<style lang="scss">
.accommodations-ledger-accounts-table {
  .save-button {
    width: 80px;
  }
  .zone-input {
    min-width: 70px;
  }
  .ledger-account-input {
    min-width: 120px;
  }
  .iban-input {
    min-width: 270px;
  }
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
