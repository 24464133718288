<template>
  <b-overlay :show="false" rounded="sm" variant="white">
    <b-card>
      <accommodations-ledger-accounts-table
        :accommodations="mappedAccommodations"
        :loading="loading"
        show-search
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";
import AccommodationsLedgerAccountsTable from "@/views/accounting/configuration/accommodations-ledger-accounts/components/AccommodationsLedgerAccountsTable.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    AccommodationsLedgerAccountsTable,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    mappedAccommodations() {
      if (!this.accommodations?.length) {
        return [];
      }

      return this.accommodations
        .filter((accommodation) => accommodation.active)
        .map((accommodation) => ({
          uuid: accommodation.uuid || null,
          name: accommodation.name || null,
          billingType: accommodation.contract?.billingType || null,
          details: accommodation.details || null,
        }));
    },
  },
  created() {
    this.fetchAccommodations();
  },
  methods: {
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
          groups: "ledgeraccounts",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
  },
};
</script>
