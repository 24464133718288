<template>
  <div>
    <h6 v-if="label || $slots.default" class="mb-50">
      <slot>
        {{ label }}
      </slot>
    </h6>
    <div class="f-checkboxes-wrapper">
      <b-form-checkbox-group v-model="localVModel" name="f-checkboxes">
        <b-form-checkbox
          v-for="option in options"
          :key="`f-checkboxes-${option.value}-option`"
          :value="option.value"
          :disabled="disabled || option.disabled"
          class="f-checkboxes-option"
        >
          {{ option.label }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </div>
  </div>
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((option) => "label" in option && "value" in option);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVModel: [],
    };
  },
  created() {
    this.localVModel = this.value;
  },
  watch: {
    value(value) {
      this.localVModel = value;
    },
    localVModel(value) {
      if (!Array.isArray(value)) {
        this.localVModel = [];
      }
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.f-checkboxes-wrapper {
  display: grid;
  width: 100%;
  gap: 1rem;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}
</style>
