<template>
  <div>
    <h6 v-if="label || $slots.default" class="mb-50">
      <slot>
        {{ label }}
      </slot>
    </h6>
    <b-input-group
      class="input-group-merge"
      :append="append"
      :prepend="prepend"
    >
      <b-form-input
        v-model="localVModel"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :lazy-formatter="true"
        :formatter="formatLedgerAccount"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
        @update="$emit('update', $event)"
      />
    </b-input-group>
  </div>
</template>

<script>
import { BInputGroup, BFormInput } from "bootstrap-vue";
import { formatLedgerAccount } from "@/utils/methods";

export default {
  components: {
    BInputGroup,
    BFormInput,
  },
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    append: {
      type: String,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVModel: null,
      formatLedgerAccount,
    };
  },
  created() {
    this.localVModel = this.value;
  },
  watch: {
    value(value) {
      this.localVModel = value;
    },
    localVModel(value) {
      this.$emit("input", value);
    },
  },
};
</script>
