<template>
  <div>
    <h6 v-if="label || $slots.default" class="mb-50">
      <slot>
        {{ label }}
      </slot>
    </h6>
    <b-form-spinbutton
      v-model="localVModel"
      :min="min"
      :max="max"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { BFormSpinbutton } from "bootstrap-vue";

export default {
  components: {
    BFormSpinbutton,
  },
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVModel: null,
    };
  },
  created() {
    this.localVModel = this.value;
  },
  watch: {
    value(value) {
      this.localVModel = value;
    },
    localVModel(value) {
      this.$emit("input", value);
    },
  },
};
</script>
