<template>
  <div>
    <h6 v-if="label || $slots.default" class="mb-50">
      <slot>
        {{ label }}
      </slot>
    </h6>
    <b-form-checkbox v-model="localVModel" :disabled="disabled" inline />
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVModel: null,
    };
  },
  created() {
    this.localVModel = this.value;
  },
  watch: {
    value(value) {
      this.localVModel = value;
    },
    localVModel(value) {
      this.$emit("input", value);
    },
  },
};
</script>
