<template>
  <div class="fr-form-select">
    <h6 v-if="label || $slots.default" class="mb-50">
      <slot>
        {{ label }}
      </slot>
    </h6>
    <v-select
      v-model="localVModel"
      :options="options"
      :reduce="(option) => option.value"
      :disabled="disabled"
      :placeholder="placeholder"
      :clearable="clearable"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((option) => "label" in option && "value" in option);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localVModel: null,
    };
  },
  created() {
    this.localVModel = this.value;
  },
  watch: {
    value(value) {
      this.localVModel = value;
    },
    localVModel(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
.fr-form-select {
  .vs__selected-options {
    input {
      &::placeholder {
        color: #bfbfc8;
      }
    }
  }
}
</style>
